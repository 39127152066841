*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

:is(::-webkit-inner-spin-button, ::-webkit-outer-spin-button) {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  font-family: Poppins, sans-serif;
}

.main-container {
  width: 100vw;
  height: 100vh;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.puzzle-screen {
  background: linear-gradient(#77e0d4 0%, #3d899a 100%);
  width: 100%;
  height: 100%;
  position: relative;
  box-shadow: 0 4px 45px #00000040;
}

.initial-screen {
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.main-screen {
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
  height: 100%;
  padding: 2rem 1.25rem 2.625rem;
  display: flex;
}

@media (width >= 1024px) {
  .main-screen {
    flex-direction: row;
    justify-content: center;
    padding: 0 5.6875rem 0 4.5rem;
  }
}

.puzzle-container {
  aspect-ratio: 1;
  z-index: 50;
  background: #3aaab5;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: auto;
  max-width: 712px;
  height: auto;
  min-height: 350px;
  max-height: 712px;
  display: flex;
  box-shadow: inset 0 0 20px #000000bf;
}

@media (width >= 1024px) {
  .puzzle-container {
    flex: 1;
    width: auto;
  }
}

.puzzle {
  grid-template-columns: repeat(3, minmax(0, 1fr));
  width: 100%;
  height: 100%;
  display: grid;
}

.puzzle-bg {
  background: #37a2ac;
  border-radius: 5px;
  width: calc(100% - 1.25rem);
  height: calc(100% - 1rem);
  box-shadow: inset 0 0 20px #00292d;
}

.puzzle-aside {
  z-index: 50;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  height: 100%;
  max-height: 712px;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  display: flex;
}

@media (width >= 1024px) {
  .puzzle-aside {
    flex: none;
    justify-content: flex-start;
    gap: 0;
  }
}

@media (width >= 1280px) {
  .puzzle-aside {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.puzzle-details {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  flex-direction: column;
  gap: 15px;
  width: 100%;
  display: flex;
}

@media (width >= 1024px) {
  .puzzle-details {
    gap: 30px;
  }
}

.puzzle-controls {
  gap: .625rem;
  display: flex;
}

@media (width >= 1024px) {
  .puzzle-controls {
    margin-top: auto;
  }
}

.puzzle-message {
  background: linear-gradient(#643181 0%, #983d9a 100%);
  border-radius: 6px;
  flex-direction: column;
  align-items: center;
  gap: .95rem;
  width: 100%;
  height: 100%;
  padding-top: 1.75rem;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
}

@media (width >= 768px) {
  .puzzle-message {
    gap: 2.5rem;
    padding-top: 5.4375rem;
    padding-bottom: 72.5px;
  }
}

.how-to-play {
  background: linear-gradient(90deg, #643282 -1.01%, #983e9b 100%);
  width: 100%;
  height: 100%;
  box-shadow: 0 4px 20px #00000026;
}

.how-to-container {
  width: 100%;
  height: 100%;
  padding: 2.325rem 1.5rem;
  position: relative;
}

@media (width >= 640px) {
  .how-to-container {
    padding-top: 3.5rem;
    padding-left: 4.3125rem;
    padding-right: 4.3125rem;
  }
}

.how-to-img {
  min-width: 102px;
  height: auto;
}

@media (width >= 640px) {
  .how-to-img {
    max-width: 192px;
  }
}

.close-button {
  -webkit-backdrop-filter: blur(32px);
  backdrop-filter: blur(32px);
  --tw-translate-x: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  cursor: pointer;
  background: #f4ede41a;
  border: 2px solid #fff;
  border-radius: 40px;
  justify-content: center;
  max-width: 53px;
  padding: 15px;
  position: absolute;
  right: 50%;
}

@media (width >= 640px) {
  .close-button {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    right: 43px;
  }
}

.button {
  -webkit-backdrop-filter: blur(32px);
  backdrop-filter: blur(32px);
  box-sizing: border-box;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
  text-align: center;
  text-transform: uppercase;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  background: #ffffff1a;
  border-radius: 3.125rem;
  justify-content: center;
  align-items: center;
  padding: 1.25rem 4.375rem;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.7rem;
  display: flex;
}

@media (width >= 640px) {
  .button {
    font-size: 1.25rem;
    line-height: 1.875rem;
  }
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.-top-24 {
  top: -6rem;
}

.z-0 {
  z-index: 0;
}

.z-50 {
  z-index: 50;
}

.mt-\[2\.3125rem\] {
  margin-top: 2.3125rem;
}

.flex {
  display: flex;
}

.hidden {
  display: none;
}

.h-\[20px\] {
  height: 20px;
}

.h-\[25px\] {
  height: 25px;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.w-\[10px\] {
  width: 10px;
}

.w-\[145px\] {
  width: 145px;
}

.w-\[188px\] {
  width: 188px;
}

.w-\[20px\] {
  width: 20px;
}

.w-\[235px\] {
  width: 235px;
}

.w-\[25px\] {
  width: 25px;
}

.w-full {
  width: 100%;
}

.min-w-\[102px\] {
  min-width: 102px;
}

.max-w-\[180px\] {
  max-width: 180px;
}

.max-w-\[220px\] {
  max-width: 220px;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-\[1\.25rem\] {
  gap: 1.25rem;
}

.gap-\[1\.3125rem\] {
  gap: 1.3125rem;
}

.gap-\[1\.5rem\] {
  gap: 1.5rem;
}

.overflow-auto {
  overflow: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded-md {
  border-radius: .375rem;
}

.border {
  border-width: 1px;
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.bg-\[\#3d899a\] {
  --tw-bg-opacity: 1;
  background-color: rgb(61 137 154 / var(--tw-bg-opacity));
}

.px-\[1\.25rem\] {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-\[17\.5px\] {
  padding-left: 17.5px;
  padding-right: 17.5px;
}

.px-\[3\.5rem\] {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}

.py-\[0\.625rem\] {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.pb-\[2rem\] {
  padding-bottom: 2rem;
}

.pb-\[3\.9375rem\] {
  padding-bottom: 3.9375rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-\[1\.5rem\] {
  padding-top: 1.5rem;
}

.pt-\[1\.625rem\] {
  padding-top: 1.625rem;
}

.pt-\[1\.75rem\] {
  padding-top: 1.75rem;
}

.pt-\[2\.4375rem\] {
  padding-top: 2.4375rem;
}

.pt-\[5\.5rem\] {
  padding-top: 5.5rem;
}

.text-center {
  text-align: center;
}

.text-\[1\.125rem\] {
  font-size: 1.125rem;
}

.text-\[1\.25rem\] {
  font-size: 1.25rem;
}

.text-\[1\.5rem\] {
  font-size: 1.5rem;
}

.text-\[1\.75rem\] {
  font-size: 1.75rem;
}

.text-\[1rem\] {
  font-size: 1rem;
}

.text-\[2\.25rem\] {
  font-size: 2.25rem;
}

.text-\[2\.4375rem\] {
  font-size: 2.4375rem;
}

.font-bold {
  font-weight: 700;
}

.font-normal {
  font-weight: 400;
}

.uppercase {
  text-transform: uppercase;
}

.leading-\[1\.5rem\] {
  line-height: 1.5rem;
}

.leading-\[1\.65rem\] {
  line-height: 1.65rem;
}

.leading-\[1\.7rem\] {
  line-height: 1.7rem;
}

.leading-\[1\.875rem\] {
  line-height: 1.875rem;
}

.leading-\[2\.25rem\] {
  line-height: 2.25rem;
}

.leading-\[2\.4375rem\] {
  line-height: 2.4375rem;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.hide {
  display: none;
}

.disabled {
  opacity: .5;
}

@media (width >= 1190px) {
  .main-screen {
    gap: 3.75rem;
    scale: 1;
  }
}

@media (width >= 1190px) and (width <= 1280px) {
  .puzzle-aside {
    padding-top: 10%;
    padding-bottom: 10%;
  }
}

@media (width >= 1024px) and (width <= 1190px) {
  .main-screen {
    scale: .9;
  }

  .puzzle-aside {
    padding-top: 15%;
    padding-bottom: 15%;
  }
}

[class^="tile"] {
  cursor: pointer;
  border-radius: 6px;
  margin: 1px;
}

[class^="tile"].transition {
  transition: transform .1s linear;
}

.tile1 {
  background-position: 0 0;
}

.tile2 {
  background-position: top;
}

.tile3 {
  background-position: 100% 0;
}

.tile4 {
  background-position: 0;
}

.tile5 {
  background-position: center;
}

.tile6 {
  background-position: 100%;
}

.tile7 {
  background-position: 0 100%;
}

.tile8 {
  background-position: bottom;
}

.tile9 {
  cursor: default;
  background: none;
}

.puzzle1 {
  background-image: url("puzzle1.91011890.png");
}

.puzzle2 {
  background-image: url("puzzle2.34a84c72.png");
}

.puzzle3 {
  background-image: url("puzzle3.63759ec9.png");
}

.puzzle4 {
  background-image: url("puzzle4.3d8e9bbc.png");
}

.puzzle5 {
  background-image: url("puzzle5.f0248b9e.png");
}

.puzzle6 {
  background-image: url("puzzle6.5ddb93ff.png");
}

.puzzle7 {
  background-image: url("puzzle7.9c191e13.png");
}

.puzzle8 {
  background-image: url("puzzle8.d9dbd012.png");
}

.puzzle9 {
  background-image: url("puzzle9.ebabdefc.png");
}

.solution1 {
  background-image: url("solution1.82fc3763.png");
}

.solution2 {
  background-image: url("solution2.2bea5f6f.png");
}

.solution3 {
  background-image: url("solution3.7b2671e8.png");
}

.solution4 {
  background-image: url("solution4.9f44adb2.png");
}

.solution5 {
  background-image: url("solution5.6a65a636.png");
}

.solution6 {
  background-image: url("solution6.3ba5ff37.png");
}

.solution7 {
  background-image: url("solution7.1a05cfc2.png");
}

.solution8 {
  background-image: url("solution8.bc0b1c33.png");
}

.solution9 {
  background-image: url("solution9.3b9c9b87.png");
}

@media (width >= 640px) {
  .sm\:block {
    display: block;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:h-\[30px\] {
    height: 30px;
  }

  .sm\:w-\[172px\] {
    width: 172px;
  }

  .sm\:w-\[292px\] {
    width: 292px;
  }

  .sm\:w-\[30px\] {
    width: 30px;
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:justify-start {
    justify-content: flex-start;
  }

  .sm\:gap-\[1\.5rem\] {
    gap: 1.5rem;
  }

  .sm\:gap-\[1\.6875rem\] {
    gap: 1.6875rem;
  }

  .sm\:gap-\[2\.5625rem\] {
    gap: 2.5625rem;
  }

  .sm\:gap-\[5px\] {
    gap: 5px;
  }

  .sm\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .sm\:pb-\[0rem\] {
    padding-bottom: 0;
  }

  .sm\:pt-0 {
    padding-top: 0;
  }

  .sm\:pt-\[2\.25rem\] {
    padding-top: 2.25rem;
  }

  .sm\:pt-\[2\.5625rem\] {
    padding-top: 2.5625rem;
  }

  .sm\:pt-\[2\.8125rem\] {
    padding-top: 2.8125rem;
  }

  .sm\:pt-\[3\.625rem\] {
    padding-top: 3.625rem;
  }

  .sm\:pt-\[3rem\] {
    padding-top: 3rem;
  }

  .sm\:text-left {
    text-align: left;
  }

  .sm\:text-start {
    text-align: start;
  }

  .sm\:text-\[1\.25rem\] {
    font-size: 1.25rem;
  }

  .sm\:text-\[1\.5rem\] {
    font-size: 1.5rem;
  }

  .sm\:text-\[1\.75rem\] {
    font-size: 1.75rem;
  }

  .sm\:text-\[3\.75rem\] {
    font-size: 3.75rem;
  }

  .sm\:text-\[3rem\] {
    font-size: 3rem;
  }

  .sm\:font-semibold {
    font-weight: 600;
  }

  .sm\:leading-\[1\.875rem\] {
    line-height: 1.875rem;
  }

  .sm\:leading-\[2\.65rem\] {
    line-height: 2.65rem;
  }

  .sm\:leading-\[3\.875rem\] {
    line-height: 3.875rem;
  }

  .sm\:leading-\[5\.65rem\] {
    line-height: 5.65rem;
  }
}

@media (width >= 768px) {
  .md\:-top-32 {
    top: -8rem;
  }

  .md\:h-\[60px\] {
    height: 60px;
  }

  .md\:w-\[60px\] {
    width: 60px;
  }

  .md\:overflow-hidden {
    overflow: hidden;
  }

  .md\:px-\[1\.5rem\] {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .md\:py-\[0\.75rem\] {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .md\:text-\[1\.375rem\] {
    font-size: 1.375rem;
  }

  .md\:text-\[1\.5rem\] {
    font-size: 1.5rem;
  }

  .md\:text-\[2\.4375rem\] {
    font-size: 2.4375rem;
  }

  .md\:font-semibold {
    font-weight: 600;
  }

  .md\:leading-\[1\.9375rem\] {
    line-height: 1.9375rem;
  }

  .md\:leading-\[1\.9rem\] {
    line-height: 1.9rem;
  }

  .md\:leading-\[2\.25rem\] {
    line-height: 2.25rem;
  }

  .md\:leading-\[2\.875rem\] {
    line-height: 2.875rem;
  }
}

@media (width >= 1024px) {
  .lg\:-top-40 {
    top: -10rem;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:items-start {
    align-items: flex-start;
  }
}

@media (width >= 1280px) {
  .xl\:px-\[72\.5px\] {
    padding-left: 72.5px;
    padding-right: 72.5px;
  }
}

/*# sourceMappingURL=index.2d05ce4c.css.map */
