@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .main-container {
    @apply w-screen h-screen;
  }
}

@layer components {
  .puzzle-screen {
    background: linear-gradient(180deg, #77e0d4 0%, #3d899a 100%);
    box-shadow: 0px 4px 45px rgba(0, 0, 0, 0.25);
    @apply relative w-full h-full;
  }
  .initial-screen {
    @apply flex flex-col items-center w-full h-full;
  }
  .main-screen {
    /* py-[4.375rem] */
    @apply w-full h-full pt-[2rem] pb-[2.625rem] lg:pb-0 lg:pt-0 px-[1.25rem] lg:pl-[4.5rem] lg:pr-[5.6875rem] flex flex-col lg:flex-row gap-[1rem] items-center lg:justify-center;
  }
  .puzzle-container {
    background: #3aaab5;
    box-shadow: inset 0px 0px 20px rgba(0, 0, 0, 0.75);
    border-radius: 5px;
    aspect-ratio: 1/1;
    @apply flex lg:flex-1 min-h-[350px] w-auto lg:w-auto max-w-[712px] max-h-[712px] justify-center items-center h-auto z-50;
  }
  .puzzle {
    @apply grid grid-cols-3 w-full h-full;
  }
  .puzzle-bg {
    background: #37a2ac;
    box-shadow: inset 0px 0px 20px #00292d;
    border-radius: 5px;
    width: calc(100% - 1.25rem);
    height: calc(100% - 1rem);
  }
  .puzzle-aside {
    @apply flex lg:flex-none flex-col lg:justify-start items-start h-full max-h-[712px] gap-[1rem] px-[1.25rem] lg:gap-0 z-50 xl:py-[3rem];
  }
  .puzzle-details {
    @apply text-white flex flex-col gap-[15px] lg:gap-[30px] w-full;
  }
  .puzzle-controls {
    @apply flex lg:mt-auto gap-[0.625rem];
  }
  .puzzle-message {
    background: linear-gradient(180deg, #643181 0%, #983d9a 100%);
    border-radius: 6px;
    /* TODO change px */
    @apply w-full h-full flex flex-col gap-[0.95rem] md:gap-[2.5rem] pt-[1.75rem] md:pt-[5.4375rem] items-center px-[15px] md:py-[72.5px];
  }
  /* How To Play Screen */
  .how-to-play {
    background: linear-gradient(90deg, #643282 -1.01%, #983e9b 100%);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    @apply w-full h-full;
  }
  .how-to-container {
    @apply w-full h-full px-[1.5rem] sm:px-[4.3125rem] py-[2.325rem] sm:pt-[3.5rem] relative;
  }
  .how-to-img {
    @apply min-w-[102px] h-auto sm:max-w-[192px];
  }
  .close-button {
    padding: 15px;
    background: rgba(244, 237, 228, 0.1);
    /* white */
    border: 2px solid #ffffff;
    backdrop-filter: blur(32px);

    /* Note: backdrop-filter has minimal browser support */
    border-radius: 40px;

    @apply absolute translate-x-1/2 sm:translate-x-0 max-w-[53px] right-[50%] sm:right-[43px] cursor-pointer justify-center;
  }
  /* General Components */
  .button {
    background: rgba(255, 255, 255, 0.1);
    /* Note: backdrop-filter has minimal browser support */
    backdrop-filter: blur(32px);

    @apply box-border font-bold uppercase text-center leading-[1.7rem] sm:leading-[1.875rem] flex justify-center items-center px-[4.375rem] py-[1.25rem] rounded-[3.125rem] border border-white text-white text-[1.125rem] sm:text-[1.25rem];
  }
}

@layer utilities {
  .no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version */
  }
  .hide {
    display: none;
  }
  .disabled {
    opacity: 0.5;
  }
}

@media (min-width: 1190px) {
  .main-screen {
    scale: 1;
    gap: 3.75rem;
  }
}

@media (min-width: 1190px ) and (max-width: 1280px) {
  .puzzle-aside {
    padding-top: 10%;
    padding-bottom: 10%;
  }
}

@media (min-width: 1024px) and (max-width: 1190px) {
  .main-screen {
    scale: 0.9;
  }
  .puzzle-aside {
    padding-top: 15%;
    padding-bottom: 15%;
  }
}

[class^="tile"] {
  cursor: pointer;
  margin: 1px;
  border-radius: 6px;
}

[class^="tile"].transition {
  transition: transform linear .1s;
}

.tile1 {
  background-position: left top;
}

.tile2 {
  background-position: center top;
}

.tile3 {
  background-position: right top;
}

.tile4 {
  background-position: left center;
}

.tile5 {
  background-position: center center;
}

.tile6 {
  background-position: right center;
}

.tile7 {
  background-position: left bottom;
}

.tile8 {
  background-position: center bottom;
}

.tile9 {
  background: transparent;
  cursor: default;
}

.puzzle1 {
  background-image: url(../assets/imgs/puzzle1.png);
}

.puzzle2 {
  background-image: url(../assets/imgs/puzzle2.png);
}

.puzzle3 {
  background-image: url(../assets/imgs/puzzle3.png);
}

.puzzle4 {
  background-image: url(../assets/imgs/puzzle4.png);
}

.puzzle5 {
  background-image: url(../assets/imgs/puzzle5.png);
}

.puzzle6 {
  background-image: url(../assets/imgs/puzzle6.png);
}

.puzzle7 {
  background-image: url(../assets/imgs/puzzle7.png);
}

.puzzle8 {
  background-image: url(../assets/imgs/puzzle8.png);
}

.puzzle9 {
  background-image: url(../assets/imgs/puzzle9.png);
}

.solution1 {
  background-image: url(../assets/imgs/solution1.png);
}

.solution2 {
  background-image: url(../assets/imgs/solution2.png);
}

.solution3 {
  background-image: url(../assets/imgs/solution3.png);
}

.solution4 {
  background-image: url(../assets/imgs/solution4.png);
}

.solution5 {
  background-image: url(../assets/imgs/solution5.png);
}

.solution6 {
  background-image: url(../assets/imgs/solution6.png);
}

.solution7 {
  background-image: url(../assets/imgs/solution7.png);
}

.solution8 {
  background-image: url(../assets/imgs/solution8.png);
}

.solution9 {
  background-image: url(../assets/imgs/solution9.png);
}